import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Rally } from '@shared/interface/rally';
import { removeHTMLTags } from '@shared/utils/rally-utils';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  public constructor(private titleService: Title, private metaService: Meta) {}

  public updateTitle(title: string): void {
    if (!title || title === '' || title === 'Rally Starter') {
      this.titleService.setTitle('Rally Starter');
      this.metaService.updateTag({ property: 'og:title', content: 'Rally Starter' });
      this.metaService.updateTag({ name: 'twitter:title', content: 'Rally Starter' });
    } else {
      this.titleService.setTitle(`${title}`);
      this.metaService.updateTag({ property: 'og:title', content: `${title}` });
      this.metaService.updateTag({ name: 'twitter:title', content: `${title}` });
    }
  }

  public updateDescription(description: string): void {
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
  }

  public updateImage(image: string): void {
    if (image !== undefined && image !== '') {
      const ext = image.split('.').pop();
      const fileTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

      if (ext && fileTypes.indexOf(ext) >= 0) {
        this.metaService.updateTag({ property: 'og:image:type', content: `image/${ext}` });
      }

      this.metaService.updateTag({ property: 'og:image', content: image });
      this.metaService.updateTag({ property: 'og:image', content: image });
      this.metaService.updateTag({ name: 'twitter:image', content: image });
    }
  }

  public updateURL(url: string): void {
    this.metaService.updateTag({ property: 'og:url', content: url });
    this.metaService.updateTag({ name: 'twitter:url', content: url });
  }

  public setMetaTags(rally: Rally | undefined, url: string) {
    if (rally) {
      this.updateTitle(`${rally?.name} | Rally Starter`);
      this.updateDescription(removeHTMLTags(rally?.description || ''));
      this.updateImage(rally?.image || '');
      this.updateURL(url);
    }
  }
}
